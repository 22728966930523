<script>
export default {
  name: 'ExpiredRequest',
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex>
      <h1>Expired request</h1>
      <p>
        The onboarding request you are attempting to access has expired. If this request needs to be continued, please
        contact the facility administrator.
      </p>
    </v-flex>
  </v-layout>
</template>
