<script>
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'
import forEach from 'lodash/forEach'

export default {
  name: 'VerifyEmail',
  data: function () {
    return {
      success: false,
      failure: false,
      mismatchPerson: null,
      trackName: 'general',
      stepName: 'email_verified',
      onBoardRequest: null,
      successMessage: 'You will be proceeding to the next step shortly...',
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    harvardKey(personData) {
      let hkey = null
      if (personData.logins) {
        forEach(personData.logins, (login) => {
          if (login.application === 'Harvard Key') {
            hkey = login.username
          }
        })
      }
      return hkey
    },
    handleMismatch(confirm) {
      const me = this

      if (confirm) {
        // Replace current data with stuff from the person fetched by the email
        this.onBoardRequest.updateFromExistingPerson(this.mismatchPerson)
      }
      this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
      updateCurrentOnBoardRequest(this.onBoardRequest)
        .then((res) => {
          this.$api.mountNextComponent(res)
        })
        .catch((error) => me.showMessage(error))
    },
    confirmEmailVerification() {
      const me = this

      getCurrentOnBoardRequest()
        .then((res) => {
          me.onBoardRequest = res
          if (!me.onBoardRequest.continuation_key) {
            me.failure = true
            me.success = false
            const err = 'Could not confirm your email address'
            me.showMessage(err)
          } else {
            me.success = true
            this.$api
              .checkEmail(me.onBoardRequest)
              .then((res2) => {
                if (
                  me.onBoardRequest.fullName
                  && res2.data[0].full_name
                  && me.onBoardRequest.fullName !== res2.data[0].full_name
                ) {
                  const hkey = me.harvardKey(res2.data[0])
                  if (hkey && me.onBoardRequest.harvardKey && hkey !== me.onBoardRequest.harvardKey) {
                    const message = 'You have a verified Harvard Key that does not match the one associated with the email you entered.  Please contact informatics@rc.fas.harvard.edu'
                    me.showMessage({ message })
                  } else {
                    // If there is another account with this email and a different full name, ask to accept what was picked up from the database
                    me.mismatchPerson = res2.data[0]
                  }
                } else if (res2.data[0].detail && res2.data[0].type) {
                  // It's actually a contact, not a person
                  const message = `The email address you provided is a contact for ${res2.data[0].name}. Please contact info@cns.fas.harvard.edu about converting to a user record.`
                  me.showMessage({ message })
                } else {
                  me.onBoardRequest.updateFromExistingPerson(res2.data[0])
                  me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
                  updateCurrentOnBoardRequest(me.onBoardRequest)
                    .then((res3) => {
                      this.$api.mountNextComponent(res3)
                    })
                    .catch((error) => me.showMessage(error))
                }
              })
              .catch((err) => {
                me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
                if (err.response && err.response.status === 404) {
                  updateCurrentOnBoardRequest(me.onBoardRequest)
                    .then((res4) => {
                      this.$api.mountNextComponent(res4)
                    })
                    .catch((err2) => {
                      me.showMessage(err2)
                    })
                } else {
                  me.failure = true
                  me.success = false
                  me.showMessage(err)
                }
              })
          }
        })
        .catch((error) => {
          me.failure = true
          me.showMessage(error)
        })
    },
  },
  mounted: function () {
    this.confirmEmailVerification()
  },
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex class="message-ctr">
      <h1>Your email has been verified</h1>
      <p v-if="!failure && !mismatchPerson">
        {{ successMessage }}
      </p>
      <p v-else-if="failure">
        An error has occurred. Please contact
        <a href="mailto:informatics@rc.fas.harvard.edu">Informatics Help</a>
        .
      </p>
      <p v-else-if="mismatchPerson">
        The verified email matches an existing record for a different full name, {{ mismatchPerson['full_name'] }}. If
        this is you, please confirm and we will continue onboarding using the existing information. If it is not you,
        please contact
        <a href="mailto:informatics@rc.fas.harvard.edu">informatics@rc.fas.harvard.edu</a>
        .
        <v-btn left class="btn" color="primary" @click.prevent="handleMismatch(true)">Confirm</v-btn>
      </p>
      <p v-else>Verifying email...</p>
    </v-flex>
  </v-layout>
</template>
