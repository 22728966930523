<script>
import StatusBar from '@/components/StatusBar'
import { IFXMessageDisplay } from 'ifxvue'

export default {
  name: 'App',
  components: {
    StatusBar,
    IFXMessageDisplay,
  },
  methods: {
    getHeight(str) {
      return this.$refs[str].clientHeight
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
  },
  computed: {
    isInnerLg: function () {
      let flag = false
      const inner = document.getElementById('inner-container')
      if (!inner) return flag
      flag = inner.classList.contains('lg-inner')
      return flag
    },
  },
}
</script>

<template>
  <v-app>
    <IFXMessageDisplay />
    <v-content>
      <v-container id="outer-container" ref="outerContainer" role="main">
        <transition name="slide-left-fade" mode="out-in">
          <router-view :key="$route.fullPath"></router-view>
        </transition>
      </v-container>
      <StatusBar />
    </v-content>
    <v-footer color="rgb(88, 88, 88)" app>
      <span class="footer-text">2022 The President and Fellows of Harvard College</span>
    </v-footer>
  </v-app>
</template>
<style lang="css">
#outer-container {
  max-width: 700px;
  height: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.lg-inner {
  margin: 20% 0;
}

.v-btn {
  margin-left: 0 !important;
  margin-right: 0 !important;
  transition: box-shadow 0s !important;
}

.v-btn:focus {
  box-shadow: 0px 0px 3px 5px #699ef7 !important;
}

.slide-left-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-left-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-left-fade-enter {
  transform: translateX(100px);
  opacity: 0;
}

.slide-left-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}

.btn {
  float: right;
  margin-top: 1rem !important;
}

.bottom-gradient {
  height: 18%;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 1)
  );
}

.v-label--is-disabled {
  color: black !important;
}

input:disabled {
  color: black !important;
}

.v-footer {
  padding: 20px;
}

.footer-text {
  color: white;
}

.required label::after {
    content: ' *';
}

@media only screen and (max-width: 767px) {
  .lg-inner {
    margin: 35% 0 25% 0;
  }
  p {
    font-size: 1.2rem;
  }
  .v-btn {
    font-size: 1.5rem !important;
    padding: 0 20px !important;
    height: 50px !important;
    margin-top: 2rem !important;
  }
}
</style>
