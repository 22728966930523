<template>
  <div class="item-card">
    <div class="item-title">{{ title }}</div>
    <div class="item-description">{{ description }}</div>
    <div class="item-action">{{ action }}</div>
  </div>
</template>

<script>
export default {
  name: 'PendingItem',
  props: {
    item: Object,
  },
  computed: {
    formattedItem: function () {
      const component = this.item.component
      let title = ''
      let description = ''
      let action = ''
      switch (component) {
        case 'ContactPIAdmin':
          title = 'Contact PI and Admin'
          description = 'The PI and User Admin for this account have been contacted, but they have not responded.'
          action = 'Wait until they do. Then you will be notified.'
          break
        case 'GetPO':
          title = 'Purchase Order'
          description = 'We need the purchase order'
          action = 'Make sure you send it to us.'
          break
        case 'GetMOU':
          title = 'Memorandum of Understanding'
          description = 'We are still reviewing the memorandum of understanding.'
          action = 'Sit tight. It might take a little while.'
          break
        case 'ConfirmSafetyTraining':
          title = 'Safety Training'
          description = 'We are still reviewing if you have enough safety training.'
          action = 'Sit tight.'
          break
        default:
          title = 'Default'
          description = 'Default'
          action = 'Do this'
          break
      }
      return { title, description, action }
    },
    title: function () {
      return this.formattedItem.title
    },
    description: function () {
      return this.formattedItem.description
    },
    action: function () {
      return this.formattedItem.action
    },
  },
  created: function () {
    // console.log(this.item)
  },
}
</script>

<style>
.item-card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  margin: 1rem 0;
  padding: 1rem;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.item-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.6rem;
}

.item-description {
  margin-bottom: 0.5rem;
}

.item-action {
  font-style: italic;
}
</style>
