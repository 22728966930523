<script>
import { mapActions, mapState } from 'vuex'
import { getCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'Loading',
  methods: {
    ...mapActions(['showMessage']),
  },
  computed: mapState({
    onBoardRequest: (state) => state.request.onBoardRequest,
  }),
  mounted() {
    const me = this
    const query = me.$route.query
    let continuationKey = null
    if (query && query.hasOwnProperty('key')) {
      continuationKey = query.key
    }
    getCurrentOnBoardRequest(continuationKey)
      .then((res) => {
        this.$api.mountNextComponent(res).catch(() => {
          const message = 'The continuation key that you entered is not valid. Please start over or contact admin'
          me.showMessage({ message })
        })
      })
      .catch((err) => {
        if (
          err.hasOwnProperty('response')
          && err.response.hasOwnProperty('data')
          && err.response.data.hasOwnProperty('error')
          && err.response.data.error.includes('request key is expired')
        ) {
          me.$router.push({ name: 'ExpiredRequest' })
        } else {
          me.showMessage(err)
        }
      })
  },
}
</script>

<template><div></div></template>
