<script>
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest, updateAccountRequest } from '@/onBoardRequest'

export default {
  name: 'CNSStaffAdmin',
  data: function () {
    return {
      onBoardRequest: null,
      trackName: 'cns_admin_staff',
      stepName: 'get_intranet_username',
      isValid: false,
      rules: [
        (v) => !!v || 'A username is required',
        (v) => ((v || '').length <= 12 && (v || '').length >= 8) || 'Username should be between 8 and 12 characters',
        (v) => RegExp(/^\w*$/).test(v || '') || 'Only alphanumeric values are allowed',
      ],
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    submit() {
      const me = this

      this.$api
        .isIntranetUsernameAvailable(this.onBoardRequest.nninAdminUsername)
        .then(() => {
          const message = `Username ${this.onBoardRequest.nninAdminUsername} is already in use.  Please choose another.`
          this.showMessage({ message })
        })
        .catch((err) => {
          if (err.response.status === 404) {
            me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
            updateCurrentOnBoardRequest(me.onBoardRequest)
              .then((res) => {
                me.onBoardRequest = res
                updateAccountRequest(me.onBoardRequest)
                  .then((res2) => {
                    this.$api.mountNextComponent(res2)
                  })
                  .catch((err2) => {
                    me.showMessage(err2)
                  })
              })
              .catch((err3) => {
                me.showMessage(err3)
              })
          } else {
            me.showMessage(err)
          }
        })
    },
  },
  mounted() {
    const me = this
    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        me.onBoardRequest.primaryAffiliation = 'Center for Nanoscale Systems (a Harvard Facility)'
        if (!me.onBoardRequest.nninAdminUsername) {
          me.onBoardRequest.setDefaultNninAdminUsername()
        }
        me.$nextTick(() => me.$refs.nninAdminUsername.focus())
      })
      .catch((err) => me.showMessage(err))
  },
}
</script>

<template>
  <v-layout v-if="onBoardRequest" align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>CNS Admin / Staff</h1>
      <p>Please enter your CNS intranet login. If you already have one, enter it here.</p>
      <p>If you do not have one, create one (lower case, letters and/or numbers, between 8 and 12 characters).</p>
      <v-form id="nnin-admin-username" v-model="isValid" @submit.prevent="submit">
        <v-layout>
          <v-text-field
            v-model="onBoardRequest.nninAdminUsername"
            label="CNS staff username"
            :rules="rules"
            class="mr-3"
            required
            ref="nninAdminUsername"
          ></v-text-field>
        </v-layout>
        <v-btn class="btn" color="secondary" type="submit" form="nnin-admin-username" :disabled="!isValid">Next</v-btn>
      </v-form>
    </v-flex>
  </v-layout>
</template>
