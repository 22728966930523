<script>
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'ConfirmID',
  data: function () {
    return {
      trackName: 'general',
      stepName: 'existing_full_name_confirmed',
      onBoardRequest: {},
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    submit() {
      const me = this

      this.$api
        .checkName(me.onBoardRequest.fullName, me.onBoardRequest.continuation_key)
        .then((res) => {
          me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
          if (me.onBoardRequest.continuation_key) {
            me.onBoardRequest.data.tracks.general.email_notified.value = 'incomplete'
            me.onBoardRequest.data.tracks.general.email_verified.value = 'incomplete'
            updateCurrentOnBoardRequest(me.onBoardRequest).then(() => this.$api.mountNextComponent(me.onBoardRequest))
          } else {
            me.onBoardRequest.updateFromExistingPerson(res.data[0])
            this.$api.mountNextComponent(me.onBoardRequest)
          }
        })
        .catch((error) => {
          me.showMessage(error)
        })
    },
  },
  created: function () {
    const me = this
    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
      })
      .catch((err) => {
        me.showMessage(err)
      })
  },
}
</script>

<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex v-if="onBoardRequest.data">
      <h1>Existing Account</h1>
      <p>It appears you already have an account on file. Here is the information we have on record:</p>
      <v-text-field label="Full Name" :value="onBoardRequest.fullName" disabled></v-text-field>
      <v-text-field label="Primary Email" :value="onBoardRequest.primaryEmail" disabled></v-text-field>
      <p>Click next to continue with registration.</p>
      <v-btn class="btn" color="secondary" @click.prevent="submit">Next</v-btn>
    </v-flex>
  </v-layout>
</template>

<style>
.user-info {
  margin: 2rem 0;
  font-size: 1.5rem;
  font-style: italic;
  color: black;
  font-weight: 500;
}
</style>
