// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import axios from 'axios'
import APIService from '@/API/API'
import APIStore from '@/API/APIStore'
import VueCookie from 'vue-cookie'
import moment from 'moment'
import ifxvue from 'ifxvue'
import vuetify from '@/plugins/vuetify'
import App from './App'
import Welcome from './components/general/Welcome'
import router from './router'
import 'vuetify/dist/vuetify.min.css'
import 'ifxvue/dist/ifxvue.css'
import '@mdi/font/css/materialdesignicons.css'
import vuexStore from './store'

Vue.use(VueCookie)

Vue.config.productionTip = false
// Pass in APIStore so ifxvue has access to application config data
Vue.use(ifxvue, { vuexStore, APIStore })

const api = new APIService(APIStore)
Vue.prototype.$api = Vue.observable(api)
router.$api = api
// api.auth.initAuthUser()

Vue.filter('yesno', (value) => {
  return value ? 'Yes' : 'No'
})
Vue.filter('humanDatetime', (value) => {
  let datestr = ''
  if (value) {
    datestr = moment(String(value)).format('M/DD/YYYY h:mm A')
  }
  return datestr
})
Vue.filter('emailDisplay', (value) => {
  let emailstr = ''
  if (value) {
    emailstr = value.replace('@', ' at ')
  }
  return emailstr
})

// Every component used by the ifxvue plugin must be globally registered
Vue.component('Welcome', Welcome)

/* eslint-disable no-new */
new Vue({
  vuetify,
  el: '#app',
  store: vuexStore,
  router,
  axios,
  components: { App },
  render: (h) => h(App),
})
