<script>
export default {
  name: 'FinalApproval',
  props: {
    contactEmail: String,
  },
}
</script>

<template>
  <v-layout align-center justify-space-between wrap fill-height>
    <v-flex class="final-ctr">
      <h1>Account Request Complete</h1>
      <p>
        Final approval of your account request is pending. You will be notified by email of the final status. If you
        have further questions, please contact
        <a :href="`mailto:${contactEmail}`">FAS Informatics</a>
        .
      </p>
      <p><strong>You may close this window.</strong></p>
    </v-flex>
  </v-layout>
</template>

<style lange="css" scoped></style>
