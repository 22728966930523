/*
    URLs for the REST API
*/
/* eslint-disable no-multi-spaces */

export const ROOT_URL = process.env.VUE_APP_DJANGO
export const API_ROOT = [ROOT_URL, 'api'].join('')
export const DJANGO_ADMIN_ROOT = [ROOT_URL, 'djadmin'].join('')
export const CAS_URL = process.env.VUE_APP_CAS
export const NANITES_ROOT = [ROOT_URL, 'nanites/api/'].join('')
