<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'GetApproverContacts',
  props: {
    trackName: String,
    helpEmail: {
      type: String,
      default: 'informatics@rc.fas.harvard.edu',
    },
  },
  data: function () {
    return {
      stepName: 'approvers_collected',
      loading: false,
      isValid: false,
      onBoardRequest: {},
      allApprovers: [],
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    skip() {
      const me = this
      me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          this.$api.mountNextComponent(res)
        })
        .catch((error) => me.showMessage(error))
    },
    submit() {
      const me = this
      this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          me.onBoardRequest = res
          me.$api.mountNextComponent(res)
        })
        .catch((err) => {
          me.showMessage(err)
        })
    },
  },
  mounted: async function () {
    const me = this
    this.loading = true
    this.onBoardRequest = await getCurrentOnBoardRequest()
      .catch((err) => me.showMessage(err))
    const res = await this.$api.getApproverContacts(this.onBoardRequest.person.affiliations)
      .catch((err) => me.showMessage(err))
    this.allApprovers = res.data
    this.loading = false
  },
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>Approver contact(s)</h1>
      <p>Select contacts that have the authority to approve your account request from the list. The PI and known lab managers for the affiliations you selected are shown.</p>
      <p>Typically this is the PI of your lab, but may be the lab manager.</p>
      <p>If the appropriate contact is not listed, enter the email address in the box, then press Enter.</p>
      <v-form id="approvers-form" ref="approversForm" v-model="isValid" @submit.prevent="submit">
        <v-flex>
          <v-combobox
            :items="allApprovers"
            :loading="loading"
            hide-no-data
            :rules="[(v) => !!v || 'At least one approver is required.']"
            v-model="onBoardRequest.approverContacts"
            :return-object="false"
            item-text="name"
            item-value="email"
            multiple
          ></v-combobox>
        </v-flex>
        <v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Submit</v-btn>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
</style>
