<script>
import { mapActions } from 'vuex'

export default {
  name: 'ContactPIAdmin',
  data: function () {
    return {
      admin_email: '',
      pi_email: '',
      success: false,
      failure: false,
      onBoardRequest: {},
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    async sendAdminEmails() {
      await this.sleep(100)
      const onBoardRequest = await this.fetchOnBoardRequest()

      const admin_types = ['pi', 'user_admin']
      const email_promises = []
      // Loop through admin types
      admin_types.forEach((admin_type) => {
        // Generate admin request object
        const admin_request = this.createAdminRequest(onBoardRequest, admin_type)
        const promise = this.$api.updateOnBoardRequest(admin_request).then((response) => {
          this.sendVerificationEmail(response.data)
          return response.data.data.primary_email
        })
        email_promises.push(promise)
      })
      Promise.all(email_promises)
        .then((res) => {
          this.onBoardRequest = onBoardRequest
          this.pi_email = res[0]
          this.admin_email = res[1]
          // this.onBoardRequest.data.tracks.
          const formatted_emails = res.join(' and ')
          this.message = `Emails have been sent to ${formatted_emails}. You will be notified once they have been notified.
          Click next to continue with the application process.`
        })
        .catch((error) => {
          this.failure = true
          this.showMessage(error)
        })
        .finally(() => {
          this.onBoardRequest.data.tracks.cns_external_user.pi_admin_contacted.value = 'pending'
          this.success = true
        })
    },
    async fetchOnBoardRequest() {
      const key = sessionStorage.getItem('onboard_continuation_key')

      return this.$api
        .getOnBoardRequest(key)
        .then((res) => res.data[0])
        .catch((error) => this.showMessage(error))
    },
    createAdminRequest() {
      // Generate request objects for PI and Admins
      return ''
    },
    sendVerificationEmail(admin_request) {
      const email = admin_request.data.primary_email

      if (!email) {
        const message = 'Your onboard request has no primary email defined. Please return to the beginning.'
        this.showMessage({ message })
      } else {
        this.$api.sendVerificationEmail(admin_request).catch((error) => this.showMessage(error))
      }
    },
    next() {
      this.$api
        .updateOnBoardRequest(this.onBoardRequest)
        .then(() => this.$api.mountNextComponent(this.onBoardRequest))
        .catch((err) => this.showMessage(err))
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
  },
  computed: {
    isComplete: function () {
      return this.success || this.failure
    },
  },
  mounted: function () {
    setTimeout(() => this.sendAdminEmails(), 2000)
  },
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex v-if="isComplete">
      <v-flex v-if="success">
        <h1>Emails have been sent successfully</h1>
        <p>
          These are the addresses of the recipients:
          <strong>{{ pi_email }}</strong>
          and
          <strong>{{ admin_email }}</strong>
          . You will be notified once they have verified their contact information and their affiliation with this
          project.
        </p>
      </v-flex>
      <v-flex v-else-if="failure">
        <h1>Emails were not sent</h1>
        <p>An error occurred when send confirmation emails to the PI and User Admin for this account.</p>
      </v-flex>
      <v-btn class="btn" color="secondary" @click.prevent="next">Next</v-btn>
    </v-flex>
    <v-flex v-else>
      <h1>Sending emails...</h1>
    </v-flex>
  </v-layout>
</template>
