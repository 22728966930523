<script>
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'
import forEach from 'lodash/forEach'

export default {
  name: 'UpdateUserInfo',
  data: function () {
    return {
      onBoardRequest: null,
      trackName: 'general',
      stepNames: ['existing_full_name_conflict', 'existing_full_name_confirmed'],
      newFullName: null,
      fullName: null,
      primaryEmail: null,
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    confirm() {
      const me = this

      this.$api
        .checkName(me.fullName, me.onBoardRequest.continuation_key)
        .then((res) => {
          // If confirmed, update with the person data returned.
          // Hang on to the primary email they specified because
          // it is likely different than the main one.
          const primaryEmail = me.onBoardRequest.primaryEmail
          me.onBoardRequest.updateFromExistingPerson(res.data[0])
          me.onBoardRequest.primaryEmail = primaryEmail
          updateCurrentOnBoardRequest(me.onBoardRequest)
            .then((res2) => {
              this.$api.mountNextComponent(res2)
            })
            .catch((error) => me.showMessage(error))
        })
        .catch((err) => {
          me.showMessage(err)
        })
    },
    submit() {
      const me = this

      this.$api
        .checkName(me.newFullName)
        .then((res) => {
          const message = 'That full name is taken. Please try again.'
          me.showMessage({ message })
          me.fullName = me.newFullName
          me.newFullName = ''
          me.primaryEmail = res.data[0].primary_email
        })
        .catch((error) => {
          if (error.response.status === 404) {
            me.onBoardRequest.fullName = me.newFullName
            forEach(me.stepNames, (stepName) => {
              me.onBoardRequest.setTrackStepComplete(me.trackName, stepName)
            })
            updateCurrentOnBoardRequest(me.onBoardRequest)
              .then((res) => {
                this.$api.mountNextComponent(res.data)
              })
              .catch((err) => {
                me.showMessage(err)
              })
          } else {
            me.showMessage(error)
          }
        })
    },
    reset() {
      this.$refs.userInfoForm.reset()
      this.newFullName = ''
    },
  },
  mounted() {
    const me = this

    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        me.fullName = me.onBoardRequest.fullName
        me.newFullName = me.fullName
        this.$api.checkName(me.newFullName).then((res2) => {
          me.primaryEmail = res2.data[0].primary_email
        })
      })
      .catch((err) => {
        me.showMessage(err)
      })
  },
}
</script>

<template>
  <v-layout v-if="onBoardRequest" align-left column justify-end fill-height>
    <v-flex grow>&nbsp;</v-flex>
    <v-flex shrink>
      <h1>Existing Full Name</h1>
      <p>
        The full name
        <strong>{{ fullName }}</strong>
        exists in our system with the following information:
      </p>
      <v-text-field :value="fullName" label="Existing Full Name" disabled></v-text-field>
      <v-text-field :value="primaryEmail" label="Primary Email" disabled></v-text-field>
      <v-form id="user-info-form" ref="userInfoForm" @submit.prevent="submit">
        <p>
          If this IS NOT you, please adjust your full name to ensure that it is unique (e.g. add middle name or initial)
          and click the "NEXT" button.
        </p>
        <p>If this IS you, click "CONFIRM".</p>
        <v-text-field v-model="newFullName" label="Full name" color="secondary" required></v-text-field>
        <v-layout row justify-end>
          <v-flex>
            <v-btn class="btn" color="primary" :disabled="!fullName" @click="confirm()" form="user-info-form">
              Confirm
            </v-btn>
          </v-flex>
          <v-flex shrink style="margin-left: 2em">
            <v-btn class="btn" color="secondary" :disabled="!newFullName" type="submit" form="user-info-form">
              Next
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>
    </v-flex>
    <v-flex grow>&nbsp;</v-flex>
  </v-layout>
</template>

<style></style>
