<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EndSessions',
  data: function () {
    return {
      user: {},
    }
  },
  methods: {
    ...mapActions(['clearTrack']),
    ...mapGetters(['getUser']),
  },
  beforeMount: function () {
    this.user = this.getUser()
    this.clearTrack()
  },
}
</script>

<template>
  <v-layout column align-center>
    <h3>Your request has been submitted.</h3>
    <p>Confirmation will be sent to {{ this.user.primary_email }}.</p>
  </v-layout>
</template>

<style></style>
