<script>
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'GetCommonData',
  data: function () {
    return {
      isValid: false,
      trackName: 'general',
      stepName: 'common_data_collected',
      generic: [(v) => !!v || 'Required field'],
      postalCodeRule: [(v) => !![5].includes(v.length) || 'Please enter a valid postal code.'],
      options: ['User', 'Admin', 'Staff', 'User Administrator'],
      fieldLoading: '',
      items: {
        country: [],
        state: [],
        city: [],
        postal_code: [],
      },
      onBoardRequest: null,
      workAddress: null,
      workPhone: null,
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    onFocus(e) {
      this.$refs[e.target.id].isMenuActive = true
    },
    setTargetItems(target) {
      this.fieldLoading = target
      // if (this.items[target].length !== 0) return
      // eslint-disable-next-line no-unused-vars
      const { country, state, city, postal_code } = this.workAddress
      let params = {}

      if (target === 'country') {
        params = { target }
      } else if (target === 'state') {
        params = { target, country }
      } else if (target === 'city') {
        params = { target, country, state }
      } else {
        params = { target, country, state, city }
      }
      this.$api
        .getLocationInfo(params)
        .then((res) => {
          const data = res.data.location_info
          if (data.length === 1) {
            this.workAddress[target] = data[0]
          }
          this.items[target] = data
        })
        .finally(() => (this.fieldLoading = ''))
        .catch((err) => console.error(err))
    },
    submit() {
      const me = this
      // Submit contact info to nanites api
      // Get validated response
      me.onBoardRequest.workAddress = me.workAddress
      me.onBoardRequest.workPhone = me.workPhone
      me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          this.$api.mountNextComponent(res)
        })
        .catch((err) => me.showMessage(err))
    },
  },
  mounted: function () {
    const me = this
    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        me.workAddress = me.onBoardRequest.workAddress
        me.workPhone = me.onBoardRequest.workPhone
        me.setTargetItems('country')
        const autocompleteList = ['countrySelect', 'stateSelect', 'citySelect', 'postalCodeSelect']
        me.$nextTick(() => {
          autocompleteList.forEach((name) => {
            me.$refs[name].$refs.input.addEventListener('focus', this.onFocus, true)
          })
          if (me.workAddress.country && me.workAddress.state && me.workAddress.city) {
            me.$refs.submit.$el.focus()
          }
        })
      })
      .catch((error) => me.showMessage(error))
  },
}
</script>

<template>
  <v-layout v-if="onBoardRequest" justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>Work Address and Phone</h1>
      <p>Please enter your work address and phone below.</p>
      <v-form id="common-data-form" ref="commonDataForm" v-model="isValid" @submit.prevent="submit">
        <v-combobox
          v-model="workAddress.country"
          label="Country"
          id="countrySelect"
          ref="countrySelect"
          :items="items.country"
          :loading="fieldLoading === 'country'"
          open-on-clear
          :rules="generic"
          color="secondary"
          required
          @input="setTargetItems('state')"
        ></v-combobox>
        <v-combobox
          v-model="workAddress.state"
          label="State / Province"
          ref="stateSelect"
          id="stateSelect"
          :items="items.state"
          :loading="fieldLoading === 'state'"
          open-on-clear
          :rules="generic"
          color="secondary"
          required
          @input="setTargetItems('city')"
        ></v-combobox>
        <v-combobox
          v-model="workAddress.city"
          label="City"
          ref="citySelect"
          id="citySelect"
          :items="items.city"
          @input="setTargetItems('postal_code')"
          :loading="fieldLoading === 'city'"
          open-on-clear
          :rules="generic"
          color="secondary"
          required
        ></v-combobox>
        <v-combobox
          v-model="workAddress.postalCode"
          label="Postal Code"
          ref="postalCodeSelect"
          id="postalCodeSelect"
          type="tel"
          @focus="setTargetItems('postal_code')"
          :loading="fieldLoading === 'postal_code'"
          :items="items.postal_code"
          open-on-clear
          :rules="generic"
          color="secondary"
          required
        ></v-combobox>
        <v-text-field
          v-model="workAddress.street1"
          label="Street Address"
          :rules="generic"
          color="secondary"
          required
        ></v-text-field>
        <v-text-field
          v-model="workPhone.contact.detail"
          label="Phone"
          :rules="generic"
          color="secondary"
          required
        ></v-text-field>
        <v-btn class="btn" color="secondary" type="submit" form="common-data-form" :disabled="!isValid" ref="submit">
          Next
        </v-btn>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<style></style>
