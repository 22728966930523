<script>
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'NotifyEmail',
  data: function () {
    return {
      onBoardRequest: {},
      success: false,
      failure: false,
    }
  },
  methods: {
    ...mapActions(['showMessage']),
  },
  created: function () {
    const me = this
    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        this.$api
          .sendVerificationEmail(me.onBoardRequest)
          .then(() => (me.success = true))
          .catch((error) => {
            me.failure = true
            me.showMessage(error)
          })
      })
      .catch((error) => this.showMessage(error))
  },
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex v-if="onBoardRequest.primaryEmail">
      <h1>Email Verification</h1>
      <p v-if="success">
        A verification email has been sent to
        <strong>{{ onBoardRequest.primaryEmail }}</strong>
        . The link provided in that email can be used to continue the application process at any time until the
        expiration date.
      </p>
      <p v-else-if="failure">
        An error has occurred. Please contact
        <a href="mailto:informatics@rc.fas.harvard.edu">Informatics Help</a>
        .
      </p>
      <p v-else>Sending email...</p>
    </v-flex>
  </v-layout>
</template>
