<script>
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'Welcome',
  data: function () {
    return {
      onBoardRequest: {},
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    noHarvardKey() {
      this.onBoardRequest.setTrackStepComplete('general', 'welcome')
      this.onBoardRequest.startWithoutHarvardKey()
      this.$api.mountNextComponent(this.onBoardRequest)
    },
    harvardKey() {
      this.onBoardRequest.setTrackStepComplete('general', 'welcome')
      this.$api.mountNextComponent(this.onBoardRequest, 'LoginWithHarvardKey')
    },
  },
  created: function () {
    getCurrentOnBoardRequest()
      .then((res) => (this.onBoardRequest = res))
      .catch((error) => this.showMessage(error))
  },
}
</script>

<template>
  <v-layout align-left column justify-end fill-height>
    <v-flex grow>&nbsp;</v-flex>
    <v-flex shrink>
      <h1>Welcome to FAS Onboard</h1>
      <p>
        This wizard will allow you to sign up for access to several FAS-managed applications. After verification of your
        email address, you will be able to return to your onboarding session any time until either your request is
        complete or the continuation key is expired.
      </p>
      <p>
        <em>Do not request more access than you currently need.</em>
        You can always return to this application if your needs change over time and unnecessary requests create extra
        work for facility administrators.
      </p>
      <p>
        This system is designed to ensure that each individual is represented uniquely so that access can be properly
        managed.
        <em>
          When asked for identifying information please use known Harvard / FAS identifiers where possible (e.g.
          HarvardKey, official email address).
        </em>
        Deliberate attempts to make multiple person records will be treated as abuse of the system.
      </p>
    </v-flex>
    <v-flex shrink>
      <p>Please log in using your HarvardKey first.</p>
    </v-flex>
    <v-flex shrink>
      <v-layout row justify-end>
        <v-flex shrink style="margin-left: 2em">
          <v-btn left class="btn" @click.prevent="harvardKey" color="secondary">Start</v-btn>
        </v-flex>
      </v-layout>
      <p>
        No HarvardKey? Start
        <a @click.prevent="noHarvardKey" xxcolor="secondary">here</a>
        instead.
      </p>
    </v-flex>
    <v-flex grow>&nbsp;</v-flex>
  </v-layout>
</template>

<style></style>
