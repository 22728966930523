<script>
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'GetRCUsername',
  data: function () {
    return {
      onBoardRequest: null,
      trackName: 'rc_approver',
      stepName: 'rc_username_collected',
      isValid: false,
      rules: [(v) => !!v || 'A username is required'],
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    submit() {
      const me = this

      if (this.onBoardRequest.rcUsername.includes('@')) {
        this.onBoardRequest.rcUsername = this.onBoardRequest.rcUsername.replace(/@.*/, '')
      }
      this.$api
        .isRCUsernameValid(this.onBoardRequest.rcUsername)
        .then(() => {
          const message = `Found username ${this.onBoardRequest.rcUsername}`
          this.showMessage({ message })
          me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
          updateCurrentOnBoardRequest(me.onBoardRequest)
            .then((res) => {
              me.onBoardRequest = res
              this.$api.mountNextComponent(res)
            })
            .catch((err) => {
              me.showMessage(err)
            })
        })
        .catch((err) => {
          if (err.response.status === 404) {
            const message = `Cannot find username ${me.onBoardRequest.rcUsername}.  Please provide a valid RC username.`
            me.showMessage({ message })
          } else {
            me.showMessage(err)
          }
        })
    },
  },
  mounted() {
    const me = this
    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        me.$nextTick(() => me.$refs.rcUsername.focus())
      })
      .catch((err) => me.showMessage(err))
  },
}
</script>

<template>
  <v-layout v-if="onBoardRequest" align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>RC Username</h1>
      <p>Please enter your RC username.</p>
      <v-form id="rc-username" v-model="isValid" @submit.prevent="submit">
        <v-layout>
          <v-text-field
            v-model="onBoardRequest.rcUsername"
            label="RC username"
            :rules="rules"
            class="mr-3"
            required
            ref="rcUsername"
            hint="RC Username.  Do not include realm (e.g. @fasrc)"
          ></v-text-field>
        </v-layout>
        <v-btn class="btn" color="secondary" type="submit" form="rc-username" :disabled="!isValid">Next</v-btn>
      </v-form>
    </v-flex>
  </v-layout>
</template>
