/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import Vue from 'vue'
import Router from 'vue-router'
import Loading from '@/components/general/Loading'
import Welcome from '@/components/general/Welcome'
import GetEmail from '@/components/general/GetEmail'
import ConfirmID from '@/components/general/ConfirmID'
import GetName from '@/components/general/GetName'
import NotifyEmail from '@/components/general/NotifyEmail'
import VerifyEmail from '@/components/general/VerifyEmail'
import GetAccessRequests from '@/components/general/GetAccessRequests'
import ContactPIAdmin from '@/components/cns/ContactPIAdmin'
import GetCommonData from '@/components/general/GetCommonData'
import UpdateUserInfo from '@/components/general/UpdateUserInfo'
import LoginWithHarvardKey from '@/components/general/LoginWithHarvardKey'
import CompletedRequest from '@/components/general/CompletedRequest'
import FinalApproval from '@/components/general/FinalApproval'
import GetPrimaryAffiliation from '@/components/general/GetPrimaryAffiliation'
import GetAllAffiliations from '@/components/general/GetAllAffiliations'
import GetPO from '@/components/external/GetPO'
import ConfirmSafetyTraining from '@/components/cns/ConfirmSafetyTraining'
import ConfirmRequestData from '@/components/cns/ConfirmRequestData'
import ReviewPendingItems from '@/components/cns/ReviewPendingItems'
import GetRCUsername from '@/components/rc/GetRCUsername'
import EndSession from '@/components/general/EndSession'
import NotFound from '@/components/general/NotFound'
import CNSStaffAdmin from '@/components/cns/CNSStaffAdmin'
import ExpiredRequest from '@/components/general/ExpiredRequest'
import GetManagedLabs from '@/components/fiine/GetManagedLabs'
import GetLabManagerPrimaryAffiliation from '@/components/fiine/GetLabManagerPrimaryAffiliation'
import GetPIPrimaryAffiliation from '@/components/fiine/GetPIPrimaryAffiliation'
import GetScientificArea from '@/components/cns/GetScientificArea'
import GetDemographicInfo from '@/components/general/GetDemographicInfo'
import IntranetLoginInfo from '@/components/internal/IntranetLoginInfo'
import ExpenseCode from '@/components/internal/ExpenseCode'
import TermsAndConditions from '@/components/internal/TermsAndConditions'
import GetProjectInfo from '@/components/cns/GetProjectInfo'
import GetMOU from '@/components/external/GetMOU'
import ProjectApproved from '@/components/external/ProjectApproved'
import GetLabInfo from '@/components/external/GetLabInfo'
import GetApproverContacts from '@/components/general/GetApproverContacts'
import { getCurrentOnBoardRequest } from '@/onBoardRequest'

const router = new Router({
  base: '/onboard/',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Loading',
      component: Loading,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/welcome/',
      name: 'Welcome',
      component: Welcome,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/get-email/',
      name: 'GetEmail',
      component: GetEmail,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/confirm-id/',
      name: 'ConfirmID',
      component: ConfirmID,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/get-name/',
      name: 'GetName',
      component: GetName,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/notify-email/',
      name: 'NotifyEmail',
      component: NotifyEmail,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/verify-email/',
      name: 'VerifyEmail',
      component: VerifyEmail,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/hers_user/get-primary-affiliation/',
      name: 'HersUserGetPrimaryAffiliation',
      component: GetAllAffiliations,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'hers_ln2_user',
        getOtherAffiliations: true,
      },
    },
    {
      path: '/hers_user/final-approval/',
      name: 'HersUserFinalApproval',
      component: FinalApproval,
      pathToRegexpOptions: { strict: true },
      props: {
        contactEmail: 'informatics@rc.fas.harvard.edu',
      },
    },
    {
      path: '/hers_helium_user/final-approval/',
      name: 'HeliumUserFinalApproval',
      component: FinalApproval,
      pathToRegexpOptions: { strict: true },
      props: {
        contactEmail: 'informatics@rc.fas.harvard.edu',
      },
    },
    {
      path: '/hers_helium_user/get-primary-affiliation/',
      name: 'HeliumUserGetPrimaryAffiliation',
      component: GetAllAffiliations,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'hers_helium_user',
        getOtherAffiliations: true,
      },
    },
    {
      path: '/cbsn_user/get-primary-affiliation/',
      name: 'CBSNUserGetPrimaryAffiliation',
      component: GetPrimaryAffiliation,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cbsn_user',
      },
    },
    {
      path: '/cbsn_user/final-approval/',
      name: 'CBSNUserFinalApproval',
      component: FinalApproval,
      pathToRegexpOptions: { strict: true },
      props: {
        contactEmail: 'informatics@rc.fas.harvard.edu',
      },
    },
    {
      path: '/cbsn_admin/get-primary-affiliation/',
      name: 'CBSNAdminGetPrimaryAffiliation',
      component: GetPrimaryAffiliation,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cbsn_admin',
      },
    },
    {
      path: '/cbsn_admin/final-approval/',
      name: 'CBSNAdminFinalApproval',
      component: FinalApproval,
      pathToRegexpOptions: { strict: true },
      props: {
        contactEmail: 'informatics@rc.fas.harvard.edu',
      },
    },
    {
      path: '/boar_user/get-primary-affiliation/',
      name: 'BOARUserGetPrimaryAffiliation',
      component: GetPrimaryAffiliation,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'boar_user',
      },
    },
    {
      path: '/boar_user/final-approval/',
      name: 'BOARUserFinalApproval',
      component: FinalApproval,
      pathToRegexpOptions: { strict: true },
      props: {
        contactEmail: 'informatics@rc.fas.harvard.edu',
      },
    },
    {
      path: '/boar_admin/get-primary-affiliation/',
      name: 'BOARAdminGetPrimaryAffiliation',
      component: GetPrimaryAffiliation,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'boar_admin',
      },
    },
    {
      path: '/boar_admin/final-approval/',
      name: 'BOARAdminFinalApproval',
      component: FinalApproval,
      pathToRegexpOptions: { strict: true },
      props: {
        contactEmail: 'informatics@rc.fas.harvard.edu',
      },
    },
    {
      path: '/cns/set-staff-admin-primary-affiliation/',
      name: 'CNSStaffAdmin',
      component: CNSStaffAdmin,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/update-user-info/',
      name: 'UpdateUserInfo',
      component: UpdateUserInfo,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/login-with-harvard-key/',
      name: 'LoginWithHarvardKey',
      component: LoginWithHarvardKey,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/cns/contact-pi-admin/',
      name: 'ContactPIAdmin',
      component: ContactPIAdmin,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    // Internal User
    {
      path: '/cns/internal/get-primary-affiliation/',
      name: 'InternalUserGetPrimaryAffiliation',
      component: GetAllAffiliations,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_internal_user',
        getOtherAffiliations: true,
        showScholarType: true,
        showDepartment: true,
      },
    },
    {
      path: '/cns/internal/get-approver-contacts/',
      name: 'InternalUserGetApproverContacts',
      component: GetApproverContacts,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_internal_user',
      },
    },
    {
      path: '/cns/internal/project-info/',
      name: 'InternalGetProjectInfo',
      component: GetProjectInfo,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_internal_user',
      },
    },
    {
      path: '/cns/internal/get-scientific-area/',
      name: 'GetScientificArea',
      component: GetScientificArea,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_internal_user',
        // getOtherAffiliations: true,
      },
    },
    {
      path: '/cns/internal/get-demographic-info/',
      name: 'InternalUserGetDemographicInfo',
      component: GetDemographicInfo,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_internal_user',
        // getOtherAffiliations: true,
      },
    },
    {
      path: '/cns/internal/intranet-login-info/',
      name: 'InternalUserIntranetLoginInfo',
      component: IntranetLoginInfo,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_internal_user',
        // getOtherAffiliations: true,
      },
    },
    {
      path: '/cns/internal/expense-code/',
      name: 'ExpenseCode',
      component: ExpenseCode,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_internal_user',
        // getOtherAffiliations: true,
      },
    },
    {
      path: '/cns/internal/confirm-safety-training/',
      name: 'InternalConfirmSafetyTraining',
      component: ConfirmSafetyTraining,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_internal_user',
      },
    },
    {
      path: '/cns/internal/terms-and-conditions/',
      name: 'TermsAndConditions',
      component: TermsAndConditions,
      // pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_internal_user',
        // getOtherAffiliations: true,
      },
    },
    {
      path: '/cns/internal/login-with-harvard-key/',
      name: 'InternalLoginWithHarvardKey',
      component: LoginWithHarvardKey,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/cns/internal/final-approval/',
      name: 'InternalUserFinalApproval',
      component: FinalApproval,
      props: {
        contactEmail: 'informatics@rc.fas.harvard.edu',
        trackName: 'cns_internal_user',
      },
    },
    // External User
    {
      path: '/cns/external/get-lab-info/',
      name: 'ExternalUserGetLabInfo',
      component: GetLabInfo,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_external_user',
      },
    },
    {
      path: '/cns/external/get-scientific-area/',
      name: 'ExternalUserGetScientificArea',
      component: GetScientificArea,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_external_user',
      },
    },
    {
      path: '/cns/external/project-info/',
      name: 'ExternalGetProjectInfo',
      component: GetProjectInfo,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_external_user',
      },
    },
    {
      path: '/cns/external/project-approved/',
      name: 'ProjectApproved',
      component: ProjectApproved,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_external_user',
        // getOtherAffiliations: true,
      },
    },
    {
      path: '/cns/external/get-demographic-info/',
      name: 'ExternalUserGetDemographicInfo',
      component: GetDemographicInfo,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_external_user',
        // getOtherAffiliations: true,
      },
    },
    {
      path: '/cns/external/mou/',
      name: 'mou',
      component: GetMOU,
      props: {
        trackName: 'cns_external_user',
      },
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/cns/external/po/',
      name: 'po',
      component: GetPO,
      props: {
        trackName: 'cns_external_user',
      },
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/cns/external/confirm-safety-training/',
      name: 'ExternalConfirmSafetyTraining',
      component: ConfirmSafetyTraining,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_external_user',
      },
    },
    {
      path: '/cns/external/final-approval/',
      name: 'ExternalUserFinalApproval',
      component: FinalApproval,
      props: {
        contactEmail: 'informatics@rc.fas.harvard.edu',
        trackName: 'cns_external_user',
      },
    },
    // CNS PI
    {
      path: '/cns/pi/get-primary-affiliation/',
      name: 'PIGetPrimaryAffiliation',
      component: GetAllAffiliations,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_pi',
        getOtherAffiliations: true,
      },
    },
    {
      path: '/cns/pi/final-approval/',
      name: 'PIFinalApproval',
      component: FinalApproval,
      props: {
        contactEmail: 'informatics@rc.fas.harvard.edu',
        trackName: 'cns_pi',
      },
    },
    {
      path: '/get-access-requests/',
      name: 'GetAccessRequests',
      component: GetAccessRequests,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    // CNS Lab Manager
    {
      path: '/cns/lab-manager/get-primary-affiliation/',
      name: 'LabManagerGetPrimaryAffiliation',
      component: GetAllAffiliations,
      pathToRegexpOptions: { strict: true },
      props: {
        trackName: 'cns_lab_manager',
        getOtherAffiliations: true,
      },
    },
    {
      path: '/cns/lab-manager/final-approval/',
      name: 'LabManagerFinalApproval',
      component: FinalApproval,
      props: {
        contactEmail: 'informatics@rc.fas.harvard.edu',
        trackName: 'cns_lab_manager',
      },
    },
    {
      path: '/cns/confirm-safety-training/',
      name: 'ConfirmSafetyTraining',
      component: ConfirmSafetyTraining,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/confirm-request-data/',
      name: 'ConfirmRequestData',
      component: ConfirmRequestData,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/review-pending-items/',
      name: 'ReviewPendingItems',
      component: ReviewPendingItems,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/get-common-data/',
      name: 'GetCommonData',
      component: GetCommonData,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/rc/get-rc-username/',
      name: 'GetRCUsername',
      component: GetRCUsername,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/rc_approver/get-primary-affiliation/',
      name: 'RCApproverGetPrimaryAffiliation',
      component: GetPrimaryAffiliation,
      pathToRegexpOptions: {
        strict: true,
      },
      props: {
        trackName: 'rc_approver',
      },
    },
    {
      path: '/rc_approver/final-approval/',
      name: 'RCApproverFinalApproval',
      component: FinalApproval,
      pathToRegexpOptions: {
        strict: true,
      },
      props: {
        contactEmail: 'informatics@rc.fas.harvard.edu',
      },
    },
    {
      path: '/fiine/get-primary-affiliation/',
      name: 'FiineGetPrimaryAffiliation',
      component: GetPrimaryAffiliation,
      pathToRegexpOptions: {
        strict: true,
      },
      props: {
        trackName: 'fiine_admin',
      },
    },
    {
      path: '/fiine/get-lab-manager-primary-affiliation/',
      name: 'FiineGetLabManagerPrimaryAffiliation',
      component: GetLabManagerPrimaryAffiliation,
      pathToRegexpOptions: {
        strict: true,
      },
      props: {
        trackName: 'fiine_lab_manager',
      },
    },
    {
      path: '/fiine/get-managed-labs/',
      name: 'FiineGetManagedLabs',
      component: GetManagedLabs,
      pathToRegexpOptions: {
        strict: true,
      },
      props: {
        trackName: 'fiine_lab_manager',
      },
    },
    {
      path: '/fiine/get-pi-primary-affiliation/',
      name: 'FiineGetPIPrimaryAffiliation',
      component: GetPIPrimaryAffiliation,
      pathToRegexpOptions: {
        strict: true,
      },
      props: {
        trackName: 'fiine_pi',
      },
    },
    {
      path: '/fiine/final-approval/',
      name: 'FiineFinalApproval',
      component: FinalApproval,
      pathToRegexpOptions: {
        strict: true,
      },
      props: {
        contactEmail: 'informatics@rc.fas.harvard.edu',
      },
    },
    {
      path: '/lockbox/hkey/internal/get-primary-affiliation/',
      name: 'LockBoxHkeyInternalUserGetPrimaryAffiliation',
      component: GetPrimaryAffiliation,
      pathToRegexpOptions: {
        strict: true,
      },
      props: {
        trackName: 'lockbox_hkey_internal_user',
      },
    },
    {
      path: '/lockbox/hkey/external/get-primary-affiliation/',
      name: 'LockBoxHkeyExternalUserGetPrimaryAffiliation',
      component: GetPrimaryAffiliation,
      pathToRegexpOptions: {
        strict: true,
      },
      props: {
        trackName: 'lockbox_hkey_external_user',
        ranks: 'company,institute,institution',
        allowOther: true,
      },
    },
    {
      path: '/lockbox/external/get-primary-affiliation/',
      name: 'LockBoxExternalUserGetPrimaryAffiliation',
      component: GetPrimaryAffiliation,
      pathToRegexpOptions: {
        strict: true,
      },
      props: {
        trackName: 'lockbox_external_user',
        ranks: 'company,institute,institution',
        allowOther: true,
      },
    },
    {
      path: '/end-session/',
      name: 'EndSession',
      component: EndSession,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/completed-request/',
      name: 'CompletedRequest',
      component: CompletedRequest,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/expired-request/',
      name: 'ExpiredRequest',
      component: ExpiredRequest,
      pathToRegexpOptions: {
        strict: true,
      },
    },
    {
      path: '/404/',
      name: 'NotFound',
      component: NotFound,
    },
    {
      path: '*',
      redirect: '/404/',
    },
  ],
})

function getAllStepsOnRequest(onBoardRequest, toComponent, previous) {
  const steps = []
  const tracks = onBoardRequest.tracks
  const trackNames = tracks.order
  for (const trackName of trackNames) {
    for (const key in tracks[trackName]) {
      const step = tracks[trackName][key]
      if (step.hasOwnProperty('value')) {
        steps.push(step)
        if (previous === true && step.component === toComponent) {
          return steps
        }
      }
    }
  }
  return steps
}

function isComponentOnRequest(onBoardRequest, toComponent) {
  let flag = false
  const allSteps = getAllStepsOnRequest(onBoardRequest, toComponent)
  allSteps.forEach((step) => {
    if (step.component === toComponent) {
      flag = true
    }
  })
  return flag
}

function arePreviousComponentsIncomplete(onBoardRequest, toComponent) {
  let flag = false
  const prevSteps = getAllStepsOnRequest(onBoardRequest, toComponent, true)
  prevSteps.forEach((step) => {
    if (step.value === 'incomplete') {
      flag = true
    }
  })
  return flag
}

router.beforeEach(async (to, from, next) => {
  let onBoardRequest = null
  if (to.name === 'ExpiredRequest' || to.name === 'LoginWithHarvardKey') {
    next()
  } else {
    onBoardRequest = await getCurrentOnBoardRequest().catch((err) => {
      if (
        err.hasOwnProperty('response')
        && err.response.hasOwnProperty('data')
        && err.response.data.hasOwnProperty('error')
        && err.response.data.error.includes('request key is expired')
      ) {
        next({ name: 'ExpiredRequest' })
      } else {
        console.log(err)
      }
    })
  }
  if (onBoardRequest) {
    // Won't exist if there is an error in retrieval
    const nextComponent = await router.$api.findNextComponent(onBoardRequest)

    if (to.name === 'Loading') {
      next()
      // If user provided url does not match nextcomponent recommended by the system
    } else if (nextComponent !== to.name) {
      // If user provided url does not match any component on request object
      if (!isComponentOnRequest(onBoardRequest, to.name)) {
        next({ name: nextComponent })
        // If user provided url matches component on request object but all previous components are incomplete
      } else if (arePreviousComponentsIncomplete(onBoardRequest, to.name)) {
        next({ name: nextComponent })
      } else {
        // If user provided url matches component on request
        next()
      }
    } else {
      next()
    }
  }
})

Vue.use(Router)

export default router
