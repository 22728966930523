<script>
import { getCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'StatusBar',
  data: function () {
    return {
      track: {
        name: '',
        title: '',
        order: [],
        length: -1,
        color: '',
        info: '',
      },
      step: {
        name: '',
        index: -1,
      },
      value: 0,
      isActive: false,
    }
  },
  methods: {
    async updateStatusBar(componentName) {
      // Sleep until system has updated
      await this.sleep(100)
      // Get onboard request
      const onBoardRequest = await getCurrentOnBoardRequest()
      const { track, step } = this.getStatusBarData(onBoardRequest, componentName)
      const value = ((step.index / track.length) * 100).toString()
      // Update that data
      this.updateAllData(track, step, value)
    },
    getStatusBarData(onBoardRequest, componentName) {
      // Initialize track and step
      const track = {
        name: '',
        title: '',
        order: [],
        length: -1,
        color: '',
        info: '',
      }
      const step = {
        name: '',
        index: -1,
      }
      // Get all track names from onboard request
      // Remove order
      const allTrackNames = onBoardRequest.tracks.order
      allTrackNames.forEach((tn) => {
        // Get all data for specific track
        const trackData = onBoardRequest.tracks[tn]
        // eslint-disable-next-line no-restricted-syntax
        for (const key in trackData) {
          // If incoming component name matches any component name in specific track
          if (trackData[key].hasOwnProperty('component') && trackData[key].component === componentName) {
            track.name = tn
            step.name = key
            break
          }
        }
      })
      if (!onBoardRequest.data.tracks[track.name]) {
        return { track, step }
      }
      // Set track data
      // Remove "completed request because it's not really a track element"

      track.order = onBoardRequest.data.tracks[track.name].order
      const completedIdx = track.order.indexOf('completed_request')
      if (completedIdx !== -1) {
        track.order.splice(completedIdx, 1)
      }
      track.length = track.order.length
      const { color, info, title } = onBoardRequest.data.tracks[track.name].status_bar
      track.color = color
      track.info = info
      track.title = title
      // Set step data
      step.index = track.order.indexOf(step.name) + 1
      return { track, step }
    },
    updateAllData(track, step, value) {
      this.track = track
      this.step = step
      this.value = value
      this.isActive = !step.name.includes('welcome')
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
  },
  computed: {
    barHeight: function () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '30'
        default:
          return '25'
      }
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.updateStatusBar(to.name)
    },
  },
  mounted: function () {
    this.updateStatusBar(this.$router.currentRoute.name)
  },
}
</script>

<template>
  <div :class="['status-container', isActive ? 'active' : 'inactive']">
    <v-progress-linear class="status-bar" :color="track.color" :height="barHeight" :value="value"></v-progress-linear>
    <div v-if="step.index !== -1 && track.length !== -1" class="status-text">
      <div class="track-info">
        <div class="track-name">{{ track.title }} Track</div>
        <v-tooltip right nudge-bottom="20">
          <template v-slot:activator="{ on }">
            <v-icon class="help-icon" v-on="on">info</v-icon>
          </template>
          <span class="tooltip-inner">{{ track.info }}</span>
        </v-tooltip>
      </div>
      <div class="step-info">Step {{ step.index }} of {{ track.length }}</div>
    </div>
  </div>
</template>

<style>
.status-container {
  position: absolute;
  width: 100%;
  height: 80px;
  transition: all ease 0.3s;
  transition-delay: 0.7s;
  margin-bottom: 20%;
}
.active {
  top: 0px;
  opacity: 1;
}
.inactive {
  top: 0px;
  opacity: 0;
}
.status-bar {
  margin: 0 !important;
}
.status-color {
  width: 100%;
  height: 100%;
  background-color: red;
}
.status-text {
  cursor: default;
  padding: 1rem;
}
.track-info {
  display: flex;
}
.track-name {
  font-size: 1.3rem;
  font-weight: 700;
}
.help-icon {
  position: relative;
  right: -5px;
  top: -1px;
  cursor: pointer;
}
#tooltip {
  opacity: 0.1 !important;
}
.tooltip-outer {
  display: none !important;
}
.tooltip-inner {
  font-size: 1rem;
  white-space: pre-line;
}
.step-info {
  font-style: italic;
  font-weight: 400;
  font-size: 1.1rem;
}

@media only screen and (max-width: 767px) {
  .track-name {
    font-size: 1.4rem;
  }
  .step-info {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
}
</style>
