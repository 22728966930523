<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest, updateAccountRequest } from '@/onBoardRequest'

export default {
  name: 'IntranetLoginInfo',
  props: {
    trackName: String
  },
  data: function () {
    return {
      stepName: 'intranet_login_info',
      loading: false,
      isValid: false,
      onBoardRequest: {},
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    onFocus() {
      this.$refs.orgSelect.isMenuActive = true
    },
    resetForm() {
      this.$refs.primaryAffiliationForm.reset()
    },
    submit() {
      const me = this
      me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          this.$api.mountNextComponent(res)
        })
        .catch((error) => me.showMessage(error))
    },
    submitOld() {
      const me = this

      this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          me.onBoardRequest = res
          updateAccountRequest(me.onBoardRequest)
            .then((res2) => {
              me.$api.mountNextComponent(res2)
            })
            .catch((err) => {
              me.showMessage(err)
            })
        })
        .catch((err) => {
          me.showMessage(err)
        })
    },
  },
  computed: {

  },
  mounted: function () {
    const me = this

    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
      })
      .catch((error) => this.showMessage(error))
  },
}
</script>
<template>
  <v-layout justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>Intranet Username/Password</h1><p>&nbsp;</p>
      <p>Please fill out the form below.</p>
      <v-form id="primary-affiliation-form" ref="primaryAffiliationForm" v-model="isValid" @submit.prevent="submit">
        <v-flex>
          <v-text-field
            v-model="onBoardRequest.cnsIntranetUsername"
            label="Intranet Username"
            color="secondary"
            ref="intranetUsername"
            required
          ></v-text-field>
          <v-text-field
            v-model="onBoardRequest.cnsIntranetPassword"
            label="Intranet Password"
            color="secondary"
            ref="intranetPassword"
            type="password"
            required
          ></v-text-field>
        </v-flex>
        <v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Next</v-btn>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
#primary-affiliation-form {
  margin-top: 1rem;
}
.pi-admin-field {
  margin: 1rem 0;
}
</style>
