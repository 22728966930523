// This file contains all application-specific to be used in ifxvue
import * as urls from '@/API/urls'

const appName = 'ifxonboard'
const appNameFormatted = 'Onboard'
const appDefaultFromField = 'onboard@fas.harvard.edu'

const vars = {
  appName,
  appNameFormatted,
  appKey: `ifx_${appName}`,
  appDefaultFromField,
}

// Initialize with empty user template before authentication occurs
const APIStore = {
  urls,
  vars,
  ui: {},
}

export default APIStore
