<script>
export default {
  name: 'CompletedRequest',
  data: function () {
    return {}
  },
}
</script>

<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex>
      <h1>
        Your request has been completed.
      </h1>
    </v-flex>
  </v-layout>
</template>
