import forEach from 'lodash/forEach'

const PERSON_ATTRIBUTES = [
  'ifxid',
  'first_name',
  'last_name',
  'full_name',
  'primary_email',
  'title',
  'primary_affiliation',
  'affiliations',
  'addresses',
  'logins',
]
const getDefaultState = () => {
  return {
    onBoardRequest: null,
  }
}

const state = getDefaultState()

const getters = {
  getOnBoardRequest: (state) => state.onBoardRequest,
}

const actions = {
  resetOnBoardRequest(context, onBoardRequest) {
    context.commit('clearOnBoardRequest', onBoardRequest)
  },
  loadOnBoardRequest(context, key) {
    return this.$api.getOnBoardRequest(key).then((response) => {
      context.commit('updateOnBoardRequest', response.data[0])
    })
  },
  updateOnBoardRequestAndSave(context, onBoardRequest) {
    context.commit('updateOnBoardRequest', onBoardRequest)
    return this.$api.updateOnBoardRequest(onBoardRequest)
  },
  updateOnBoardRequestFromPersonAndSave(context, person) {
    context.commit('updateOnBoardRequestFromPerson', person)
    return this.$api.updateOnBoardRequest(context.state.onBoardRequest)
  },
}

const mutations = {
  updateOnBoardRequest(state, onBoardRequest) {
    state.onBoardRequest = onBoardRequest
  },
  updateOnBoardRequestFromPerson(state, person) {
    forEach(PERSON_ATTRIBUTES, (attr) => {
      if (typeof person[attr] !== 'undefined') {
        state.onBoardRequest.data[attr] = person[attr]
      }
    })
  },
  clearOnBoardRequest(state) {
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
