<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest, updateAccountRequest } from '@/onBoardRequest'

export default {
  name: 'GetPrimaryAffiliation',
  props: {
    trackName: String,
    orgTree: {
      type: String,
      default: 'Harvard',
    },
    ranks: {
      type: String,
      default: 'lab,department,facility,center,museum,division,institute,school,institution',
    },
    allowOther: {
      type: Boolean,
      default: false,
      required: false,
    },
    helpEmail: {
      type: String,
      default: 'informatics@rc.fas.harvard.edu',
    },
  },
  data: function () {
    return {
      stepName: 'affiliation_collected',
      loading: false,
      isValid: false,
      onBoardRequest: {},
      organizations: [],
      primaryAffiliation: '',
      enteredAffiliation: '',
      rules: [(v) => {
        if (!v && !this.enteredAffiliation) {
          console.log(this.enteredAffiliation)
          return 'Affiliation value is required'
        }
        return true
      }],
      errors: {},
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    onFocus() {
      this.$refs.orgSelect.isMenuActive = true
    },
    resetForm() {
      this.$refs.primaryAffiliationForm.reset()
    },
    clearErrors() {
      this.errors = {}
    },
    submit() {
      if (!this.onBoardRequest.primaryAffiliation && !this.onBoardRequest.enteredAffiliation) {
        this.$refs.primaryAffiliationForm.isValid = false
        this.errors = {
          primaryAffiliation: 'Affiliation value is required',
          enteredAffiliation: 'Affiliation value is required',
        }
        return false
      }

      const me = this

      this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          me.onBoardRequest = res
          updateAccountRequest(me.onBoardRequest)
            .then((res2) => {
              this.$api.mountNextComponent(res2)
            })
            .catch((err) => {
              me.showMessage(err)
            })
        })
        .catch((err) => {
          me.showMessage(err)
        })
      return true
    },
  },
  watch: {
    primaryAffiliation: function (val) {
      this.onBoardRequest.enteredAffiliation = ''
      this.onBoardRequest.primaryAffiliation = val
    },
    enteredAffiliation: function (val) {
      this.onBoardRequest.primaryAffiliation = ''
      this.onBoardRequest.enteredAffiliation = val
    },
  },
  computed: {
    searchPlaceholder: function () {
      const base = 'Begin typing your affiliation name'
      return this.loading ? `${base} (loading)` : base
    },
  },
  mounted: function () {
    const me = this
    this.loading = true

    this.$api
      .getOrgNames(this.orgTree, null, this.ranks)
      .then((res) => {
        me.organizations = res.data
        getCurrentOnBoardRequest()
          .then((res2) => {
            me.onBoardRequest = res2
            me.primaryAffiliation = me.onBoardRequest.primaryAffiliation
            me.enteredAffiliation = me.onBoardRequest.enteredAffiliation
            me.$nextTick(() => me.$refs.orgSelect.focus())
          })
          .catch((err) => me.showMessage(err))
      })
      .catch((err) => me.showMessage(err))
      .finally((me.loading = false))
  },
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>What is your primary affiliation?</h1>
      <p>Select your organization from the list<span v-if="allowOther"> or enter an appropriate value</span>.</p>
      <p>Be as specific as possible.</p>
      <v-form id="primary-affiliation-form" ref="primaryAffiliationForm" v-model="isValid" @submit.prevent="submit">
        <v-flex>
          <v-select
            :items="organizations"
            key="organization-list"
            ref="orgSelect"
            autocomplete="new-password"
            :placeholder="searchPlaceholder"
            item-text="name"
            item-value="slug"
            prepend-icon="search"
            :loading="loading"
            hide-no-data
            v-model="primaryAffiliation"
            :return-object="false"
            clearable
            :error-messages="errors.primaryAffiliation"
            @focus="clearErrors()"
          ></v-select>
        </v-flex>
        <v-flex v-if="allowOther">
          <v-text-field
            v-model="enteredAffiliation"
            label="Or enter another affiliation"
            clearable
            :error-messages="errors.enteredAffiliation"
            @focus="clearErrors()"
          ></v-text-field>
        </v-flex>
        <v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Submit</v-btn>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
#primary-affiliation-form {
  margin-top: 1rem;
}
.pi-admin-field {
  margin: 1rem 0;
}
</style>
