<script>
import { CAS_URL } from '@/API/urls'
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest, updateAccountRequest } from '@/onBoardRequest'

export default {
  name: 'LoginWithHarvardKey',
  data: function () {
    return {
      loading: false,
      verified: false,
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    submit() {
      window.location.href = `${CAS_URL}`
    },
    checkForHarvardKey() {
      const ticket = this.$route.query.ticket
      if (ticket) {
        this.verifyHarvardKey(ticket)
      } else {
        this.loading = false
      }
    },
    async verifyHarvardKey(ticket) {
      const me = this
      // If there is no key, there probably isn't an onboard request, so get a default and save
      let onBoardRequest = await getCurrentOnBoardRequest().catch((err) => {
        me.showMessage(err)
      })
      onBoardRequest.setTrackStepComplete('general', 'welcome')
      let continuation_key = onBoardRequest.continuation_key
      if (!continuation_key) {
        onBoardRequest = await updateCurrentOnBoardRequest(onBoardRequest, true).catch((err) => {
          me.showMessage(err)
        })
        continuation_key = onBoardRequest.continuation_key
      }

      this.$api
        .harvardKey(ticket, continuation_key)
        .then(async () => {
          me.verified = true
          me.loading = false
          getCurrentOnBoardRequest()
            .then((res) => {
              setTimeout(() => {
                this.$api.mountNextComponent(res)
              }, 2000)
            })
            .catch((err) => {
              me.showMessage(err)
            })
        })
        .catch((err) => {
          me.loading = false
          me.showMessage(err)
        })
    },
  },
  created() {
    this.loading = true
    this.checkForHarvardKey()
  },
  mounted() {
    // May have arrived here after key has already been set, so need to check.
    const me = this

    getCurrentOnBoardRequest()
      .then((res) => {
        const onBoardRequest = res
        if (onBoardRequest.harvardKey) {
          onBoardRequest.setHarvardKeyStepsComplete()
          updateCurrentOnBoardRequest(onBoardRequest)
            .then((res2) => {
              me.onBoardRequest = res2
              if (me.onBoardRequest.continuation_key) {
                updateAccountRequest(me.onBoardRequest)
                  .then((res3) => {
                    me.$api.mountNextComponent(res3)
                  })
                  .catch((err) => {
                    me.showMessage(err)
                  })
              }
            })
            .catch((err) => {
              me.showMessage(err)
            })
        }
        if (!onBoardRequest.continuation_key) {
          updateCurrentOnBoardRequest(onBoardRequest).catch((err) => {
            me.showMessage(err)
          })
        }
      })
      .catch((err) => {
        me.showMessage(err)
      })
  },
}
</script>

<template>
  <v-layout v-if="!loading" align-center justify-space-between row fill-height>
    <v-flex v-if="!verified">
      <h1>Please log in with your Harvard Key</h1>
      <p>
        If you do not have a Harvard Key and the access you are requesting does not require one, go back to the
        <router-link :to="{ name: 'Welcome' }">Welcome page</router-link>
        and use the link at the bottom of the page.
      </p>
      <p>
        If you do not have one and the access you are requesting does require it, you will need to apply for one and
        wait until it is available to complete your request for access.
      </p>
      <v-btn class="btn" color="secondary" @click.prevent="submit">Log In</v-btn>
    </v-flex>
    <v-flex v-else>
      <h1>You have been verified with Harvard Key</h1>
      <p>You will be proceeding to the next step shortly...</p>
    </v-flex>
  </v-layout>
</template>

<style></style>
