<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest, updateAccountRequest } from '@/onBoardRequest'

export default {
  name: 'ConfirmSafetyTraining',
  props: {
    trackName: String,
  },
  data: function () {
    return {
      stepName: 'safety_training',
      loading: false,
      isValid: false,
      onBoardRequest: {},
      search: '',
      safetyFiles: {
        safetyForm: {
          name: '',
          data: '',
          stepName: '',
          trackName: '',
          category: 'Safety Form'
        },
        safetyCertificate: {
          name: '',
          data: '',
          stepName: '',
          trackName: '',
          category: 'Safety Certificate'
        }
      }
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    submit() {
      this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
      if (!this.onBoardRequest.data.request_files) {
        this.onBoardRequest.data.request_files = []
      }
      this.onBoardRequest.data.request_files.push(
        {
          step_name: this.stepName,
          track_name: this.trackName,
          file: this.safetyFiles.safetyForm.name,
          category: this.safetyFiles.safetyForm.category,
        },
        {
          step_name: this.stepName,
          track_name: this.trackName,
          file: this.safetyFiles.safetyCertificate.name,
          category: this.safetyFiles.safetyCertificate.category,
        },
      )
      const me = this
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then(async () => {
          await this.$api.loadRequestFile(this.safetyFiles.safetyForm, me.onBoardRequest.id)
          await this.$api.loadRequestFile(this.safetyFiles.safetyCertificate, me.onBoardRequest.id)
          updateAccountRequest(me.onBoardRequest)
            .then((res2) => {
              this.$api.mountNextComponent(res2)
            })
            .catch((err) => {
              me.showMessage(err)
            })
        })
        .catch(error => {
          const { response } = error
          if (response) {
            this.errors = response.data
          }
          if (response.status !== 400) {
            this.showMessage(error)
          }
        });
    },
    pickFormFile() {
      this.$refs.formFile.click()
    },
    pickCertificateFile() {
      this.$refs.certificateFile.click()
    },
    onFilePicked(e, safetyFileType) {
      const files = e.target.files
      if (files[0] === undefined) {
        this.safetyFiles[safetyFileType].name = ''
        this.safetyFiles[safetyFileType].readAsDataURL = ''
        const message = 'There is no file selected'
        this.showMessage({ message })
      } else {
        const filename = files[0].name
        this.safetyFiles[safetyFileType].name = filename
        if (this.safetyFiles[safetyFileType].name.lastIndexOf('.') <= 0) {
          const message = 'File format must have an acceptable extension (.jpg, .png, .pdf)'
          this.showMessage({ message })
        } else {
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.safetyFiles[safetyFileType].data = files[0]
            this.safetyFiles[safetyFileType].name = filename
          })
        }
      }
    },
  },
  mounted: function () {
    const me = this
    this.loading = true
    this.safetyFiles.safetyForm.stepName = this.stepName;
    this.safetyFiles.safetyForm.trackName = this.trackName;
    this.safetyFiles.safetyCertificate.stepName = this.stepName;
    this.safetyFiles.safetyCertificate.trackName = this.trackName;

    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
      })
      .catch((error) => me.showMessage(error))
  },
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>Safety Training</h1><p>&nbsp;</p>
      <v-form id="get-safety-form" ref="getSafetyForm" v-model="isValid" @submit.prevent="submit">
        <v-layout column>
          <v-flex>Please attach the completed safety form...</v-flex>
          <v-flex>
            <v-text-field
              label="Safety Form"
              @click='pickFormFile'
              v-model='safetyFiles.safetyForm.name'
              prepend-icon='attach_file'
              hint="Upload completed Safety Form"
              color="secondary"
            >
            </v-text-field>
            <input
              type="file"
              style="display: none"
              ref="formFile"
              accept="application/pdf"
              @change="(e)=>onFilePicked(e, 'safetyForm')"
            />
          </v-flex>
          <v-flex>... and the Safety Course Certificate</v-flex>
          <v-flex>
            <v-text-field
              label="Safety Course Certificate"
              @click='pickCertificateFile'
              v-model='safetyFiles.safetyCertificate.name'
              prepend-icon='attach_file'
              hint="Upload completed Safety Course Certificate"
              color="secondary"
            >
            </v-text-field>
            <input
              type="file"
              style="display: none"
              ref="certificateFile"
              accept="application/pdf"
              @change="(e)=>onFilePicked(e, 'safetyCertificate')"
            />
          </v-flex>
        </v-layout>
        <v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Next</v-btn>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
#get-mou-form {
  margin-top: 1rem;
}
.pi-admin-field {
  margin: 1rem 0;
}
</style>
