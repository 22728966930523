<script>
/* eslint-disable guard-for-in */
import { mapActions } from 'vuex'
import PendingItem from '@/components/PendingItem'

export default {
  name: 'ReviewPendingItems',
  components: {
    PendingItem,
  },
  data: function () {
    return {
      loading: true,
      onBoardRequest: {},
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    next() {
      const me = this

      me.onBoardRequest.data.tracks.cns_external_user.pending_items_reviewed.value = 'complete'
      this.$api
        .updateOnBoardRequest(me.onBoardRequest)
        .then(() => this.$api.mountNextComponent(me.onBoardRequest))
        .catch((err) => me.showMessage(err))
    },
  },
  computed: {
    pendingItems: function () {
      const request = JSON.parse(JSON.stringify(this.onBoardRequest.data))
      const requestType = request.access_requests[0]
      const track = request.tracks[requestType]
      const pending = []
      // eslint-disable-next-line no-restricted-syntax
      for (const key in track) {
        const obj = track[key]
        if (obj.hasOwnProperty('value') && obj.value === 'pending') {
          pending.push(obj)
        }
      }
      return pending
    },
  },
  beforeMount: function () {
    const me = this

    const key = sessionStorage.getItem('onboard_continuation_key')
    if (key) {
      this.$api
        .getOnBoardRequest(key)
        .then((res) => (me.onBoardRequest = res.data[0]))
        .finally(() => (me.loading = false))
    }
  },
}
</script>

<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex v-if="!loading" class="lg-inner">
      <h1>Pending Items</h1>
      <p>This is a summary of the pending items on the account request.</p>
      <v-layout column align-center>
        <PendingItem v-for="item in pendingItems" :item="item" :key="item.component" />
      </v-layout>
      <v-btn class="btn" @click.prevent="next()" color="secondary">Next</v-btn>
    </v-flex>
    <!-- <div class="bottom-gradient"></div> -->
  </v-layout>
</template>
