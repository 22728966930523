<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest, updateAccountRequest } from '@/onBoardRequest'

export default {
  name: 'ExpenseCode',
  props: {
    trackName: String,
    helpEmail: {
      type: String,
      default: 'informatics@rc.fas.harvard.edu',
    }
  },
  data: function () {
    return {
      stepName: 'expense_code',
      loading: false,
      isValid: false,
      onBoardRequest: {},
      search: '',
      codeInput: '',
      rules: [
        (v) => !!v || 'A value is required',
      ],
      errors: {},
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    clearErrors() {
      this.errors = {}
    },
    submit() {
      const me = this
      this.normalizeExpenseCode()
      this.$api.checkExpenseCode(me.onBoardRequest)
        .then(() => {
          me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
          updateCurrentOnBoardRequest(me.onBoardRequest)
            .then((res) => {
              me.onBoardRequest = res
              updateAccountRequest(me.onBoardRequest)
                .then((res2) => {
                  me.$api.mountNextComponent(res2)
                })
                .catch((err) => {
                  me.showMessage(err)
                })
            })
            .catch((err) => {
              me.showMessage(err)
            })
        })
        .catch((error) => {
          this.errors = error.response.data
          me.showMessage(error)
        })
    },
    normalizeExpenseCode() {
      const me = this
      // let code = me.onBoardRequest.expenseCode
      let code = me.codeInput
      if (!code) return
      // Accept both - and . as separators
      code = code.replace(/[-.]/g, ';').split(';')
      // Third segment of code must be '8250' and inserted if missing
      if (code.length !== 7 && code[2].length !== 4) {
        code.splice(2, 0, '8250')
      }
      if (code.length === 7) {
        code[2] = '8250'
      }
      code = code.join('-')
      me.codeInput = code
      me.onBoardRequest.expenseCode = me.codeInput
    }
  },
  mounted: function () {
    const me = this
    this.loading = true

    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
      })
      .catch((error) => me.showMessage(error))
  },
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>Expense Code</h1><p>&nbsp;</p>
      <p>
        Please enter the main expense code you will be using.<br/>
        If multiple codes, splits, etc. will be used, contact <a href="mailto:info@cns.fas.harvard.edu">CNS staff</a>.
      </p>
      <p>
        The root of the code (last 5 digits) should match the code for at least one of the affiliations you listed.
      </p>
      <v-form id="primary-affiliation-form" ref="primaryAffiliationForm" v-model="isValid" @submit.prevent="submit">
        <v-flex>
          <v-text-field
            v-model="codeInput"
            @blur="normalizeExpenseCode"
            label="Expense Code"
            color="secondary"
            ref="expenseCode"
            required
            class="required"
            :rules="rules"
            :error-messages="errors.expense_code"
            @focus="clearErrors"
          ></v-text-field>
        </v-flex>
        <v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Next</v-btn>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
#primary-affiliation-form {
  margin-top: 1rem;
}
.pi-admin-field {
  margin: 1rem 0;
}
</style>
