<script>
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'
import forOwn from 'lodash/forOwn'

export default {
  name: 'GetAccessRequests',
  data: function () {
    return {
      selected: [],
      trackName: 'general',
      stepName: 'access_requests_checked',
      valid: false,
      onBoardRequest: {},
      facilities: [
        {
          prefix: 'bauer',
          name: 'Bauer Core Lockbox LIMS',
          roles: [
            {
              role: 'lockbox_hkey_internal_user',
              name: 'Harvard Key internal user',
              explanation: 'User of the Bauer Core Lockbox LIMS with Harvard Key authentication and 33 digit code.',
            },
            {
              role: 'lockbox_hkey_external_user',
              name: 'Harvard Key external user',
              explanation: 'User of the Bauer Core Lockbox LIMS with Harvard Key authentication and PO billing.',
            },
            {
              role: 'lockbox_external_user',
              name: 'External user',
              explanation: 'PO used for billing, passwordless LockBox authentication',
            },
          ],
        },
        {
          prefix: 'cns',
          name: 'Center for Nanoscale Systems',
          roles: [
            {
              role: 'cns_admin_staff',
              name: 'Staff account',
              explanation:
                'CNS back office administrator or instrument staff member. Needed for access to NICE system for tool data maintenance.',
            },
            {
              role: 'cns_internal_user',
              name: 'Harvard user',
              explanation:
                'CNS internal user.  This means that you will be using a 33 digit expense code for billing.',
            },
            {
              role: 'cns_external_user',
              name: 'Non-Harvard user',
              explanation:
                'CNS external users are those that use POs for billing rather than 33 digit expense codes.  Harvard affiliated hospitals, for example, are usually considered external.',
            },
            {
              role: 'cns_pi',
              name: 'PI',
              explanation:
                'Principal Investigator. Used mainly for account request approvals.',
            },
            {
              role: 'cns_lab_manager',
              name: 'Lab Manager',
              explanation:
                'Primarily for lab managers who need to approve account requests or manage billing information.',
            },
          ],
        },
        {
          prefix: 'rc',
          name: 'Research Computing',
          roles: [
            {
              role: 'rc_approver',
              name: 'RC Request Approver',
              explanation:
                'Access to the RC account request / access grant approval tool. Generally limited to PIs, lab administrators, and other account sponsors.',
            },
          ],
        },
        {
          prefix: 'hers',
          name: 'HeRS (Harvard University Helium or Biolabs LN2)',
          roles: [
            {
              role: 'hers_ln2_user',
              name: 'LN2 User',
              explanation: 'User of Biolabs LN2 Facility.',
            },
            {
              role: 'hers_helium_user',
              name: 'Helium User',
              explanation: 'User of Harvard University Helium.',
            },
          ],
        },
        {
          prefix: 'fiine',
          name: 'Fiine (FAS Instrument Invoicing Environment)',
          roles: [
            {
              role: 'fiine_lab_manager',
              name: 'Lab administrator',
              explanation:
                'Permits the use of the Fiine system for expense code management, user authorization, and monthly billing record review for administered labs.',
            },
            {
              role: 'fiine_pi',
              name: 'PI',
              explanation:
                'Permits the use of the Fiine system for expense code management, user authorization, and monthly billing record review for administered labs.',
            },
            {
              role: 'fiine_admin',
              name: 'Financial administrator',
              explanation: 'Financial staff whose job includes invoice generation, general ledger journal entry.',
            },
          ],
        },
        {
          prefix: 'cbsn',
          name: 'CBSN (Center for Brain Science Neuroimaging)',
          roles: [
            {
              role: 'cbsn_user',
              name: 'CBSN User',
              explanation: 'User of the Center for Brain Science Neuroimaging Facility.',
            },
            {
              role: 'cbsn_admin',
              name: 'CBSN Admin',
              explanation: 'Administrator of the Center for Brain Science Neuroimaging Facility.',
            },
          ],
        },
        {
          prefix: 'boar',
          name: 'BOAR (Billing for Office of Animal Resources)',
          roles: [
            {
              role: 'boar_user',
              name: 'BOAR User',
              explanation: 'User of the Office of Animal Resources billing application.',
            },
            {
              role: 'boar_admin',
              name: 'BOAR Admin',
              explanation: 'Administrator of the Office of Animal Resources billing application.',
            },
          ],
        },
      ],
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    deselectAll() {
      this.cns = ''
    },
    submit() {
      this.onBoardRequest.addTracksFromAccessRequests()
      this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
      const me = this
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then(() => {
          this.$api.mountNextComponent(me.onBoardRequest)
        })
        .catch((err) => me.showMessage(err))
    },
    setFocus() {
      this.$refs.accessRequestSubmitButton.$el.focus()
    },
    alreadyHasRole(facility, role) {
      if (role && role.includes('lockbox_hkey')) {
        if (!this.onBoardRequest.harvardKey) {
          return true
        }
      }
      const requests = Object.entries(this.onBoardRequest.accessRequests)
      const matches = requests.filter((request) => {
        const [key, value] = request
        return key.indexOf(facility.prefix.toLowerCase()) !== -1 && value === 'Current'
      })
      return !!matches.length
    },
    handleChange(facility, index, value) {
      // console.log(facility, index, value);
      // Make sure that the checkboxes are "unique"
      // i.e. They will act like radio buttons in that only one
      // role can be selected per facility
      facility.roles.forEach((role, i) => {
        if ((index !== i && this.onBoardRequest.accessRequests[role.role]) || (index === i && !value)) {
          delete this.onBoardRequest.accessRequests[role.role]
        }
      })
    },
  },
  watch: {
    selected: function () {
      this.$nextTick(() => this.$refs.accessRequestSubmitButton.$el.focus())
    },
  },
  computed: {
    noRequestedAccess: function () {
      let result = true
      forOwn(this.onBoardRequest.accessRequests, (key, val) => {
        if (val === 'Request') {
          result = false
        }
      })
      return result
    },
  },
  mounted: function () {
    const me = this
    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
      })
      .catch((error) => me.showMessage(error))
  },
}
</script>

<template>
  <v-layout v-if="onBoardRequest.accessRequests" justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>Access Requests</h1>
      <p>Check boxes for the type of access you are requesting.</p>
      <p>
        <em>
          Do not check more than you currently require. If you have not been specifically instructed to select a
          particular type of access, do not assume that you need it.
        </em>
      </p>
      <p>
        You already have access to Facilities marked with
        <v-icon color="teal" small>mdi-check</v-icon>
        . The
        <v-icon small>thumb_up</v-icon>
        icon indicates which access you have.
      </p>

      <v-form id="access-request-form" ref="accessRequestForm" @submit.prevent="submit">
        <v-layout column>
          <v-expansion-panels>
            <v-expansion-panel v-for="(facility, index) in facilities" :key="`${facility.prefix}-${index}`">
              <v-expansion-panel-header class="access-group-title" :disable-icon-rotate="alreadyHasRole(facility)">
                {{ facility.name }}
                <template v-if="alreadyHasRole(facility)" v-slot:actions>
                  <v-icon color="teal">mdi-check</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="facility.roles.length !== 0">
                <p v-if="!alreadyHasRole(facility) && facility.roles.length > 1" class="text-body-2 mb-0">
                  You may only select one type of access.
                </p>
                <v-flex class="ctr">
                  <v-row
                    class="radio-layout"
                    v-for="(role, index) in facility.roles"
                    :key="`${facility.prefix}-role-${index}`"
                  >
                    <v-col cols="5">
                      <v-checkbox
                        class="access-checkbox"
                        v-model="onBoardRequest.accessRequests[role.role]"
                        value="Request"
                        :indeterminate="onBoardRequest.accessRequests[role.role] === 'Current'"
                        indeterminate-icon="thumb_up"
                        :disabled="alreadyHasRole(facility, role.role)"
                        color="success"
                        :label="role.name"
                        @change="handleChange(facility, index, $event)"
                      ></v-checkbox>
                    </v-col>
                    <v-col>
                      <p>{{ role.explanation }}</p>
                    </v-col>
                  </v-row>
                  <p v-if="alreadyHasRole(facility)" class="text-body-2">
                    To change your access, please contact the Facility Administrator
                  </p>
                </v-flex>
              </v-expansion-panel-content>
              <v-expansion-panel-content v-else>
                <v-flex>
                  <v-row>
                    <v-col>
                      <div class="text-h6 text-center font-weight-light blue-grey--text lighten-4">
                        No access available
                      </div>
                    </v-col>
                  </v-row>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </v-form>
      <v-layout justify-end>
        <v-btn class="btn" color="secondary" form="access-request-form" type="submit" ref="accessRequestSubmitButton">
          Next
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<style>
.access-content {
  justify-content: center;
  align-items: center;
}

.v-input__control {
  width: 100% !important;
}

.ctr {
  padding: 2rem 1rem;
}

.radio-layout {
  justify-content: start;
}

.access-group-title {
  font-weight: 500;
  font-size: larger;
  color: black;
}

.access-checkbox {
  height: 35px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
</style>
